@tailwind base;
@tailwind components;
@tailwind utilities;

/* adding fonts below */
@font-face {
    font-family: "Montserrat";
    src: url(./assets/fonts/MontserratLight.ttf) format("truetype");
    font-weight: 300;
    font-style: normal;
  }
@font-face {
    font-family: "Montserrat";
    src: url(./assets/fonts/MontserratRegular.ttf) format("truetype");
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: "Montserrat";
    src: url(./assets/fonts/MontserratMedium.ttf) format("truetype");
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: "Montserrat";
    src: url(./assets/fonts/MontserratBold.ttf) format("truetype");
    font-weight: 700;
    font-style: normal;
}
  
/* adding fonts above */

html, body, #root{
    height: 100%;
}


/* //notifications */
.notification > .notification__item--danger{
    @apply bg-red-500 border-none 
}
.notification .notification__close{
    @apply bg-transparent !important
}
.notification .notification__close:after{
    @apply text-2xl
}

.notification > .notification__item--success{
    @apply bg-white border-none rounded-lg w-full
}
.notification > .notification__item--danger{
    @apply bg-white border-none rounded-lg
}

.notification__item--success .notification__timer{
    @apply bg-white invisible
}
.notification__content {
    display: flex !important;
    justify-content: space-between;
    align-items: center;
    padding: 20px !important;
}
.notification__message{
    @apply w-full flex items-center justify-center
}
.notification__item--danger .notification__timer{
    @apply bg-white invisible
}
.notification__item--success .notification__timer, .notification__item--danger .notification__timer{
    @apply absolute
}
.notification__item--success .notification__message{
@apply text-black
}
.notification__item--danger .notification__message{
@apply text-red-600
}
.apexcharts-toolbar{
    display: none !important;
}
/*// notification*/

.pagination{
    display: flex;
    justify-content: center;
    align-items: center;
    /* background: #043c7b; */
    margin-top: 20px;
    /* color: #fff; */
    color: #ec9900;
    width: 100%;
    border-radius: 20px;
    padding: 10px 50px;
    min-width: 300px;
    /* max-width: 400px; */
    margin-left: auto;
    margin-right: auto;
    overflow-x: auto;
    @apply lg:w-2/4 
  }
  
  .pagination li {
    margin: 0 10px;
    height: 35px;
    width: 35px;
    border-radius: 50%;
    flex-shrink: 0;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .pagination .active{
    border: 1px solid #ec9900;
  }